<template>
    <div class="page-index">
        <md-tabs ref="tabs" @change="tabChange">
            <md-tab md-label="Pages" :md-active="tabIndex==0">
                <page-list ref="list" v-if="tabIndex==0"></page-list>
            </md-tab>
            <md-tab md-label="Add Page" :md-active="tabIndex==1">
                <page-edit ref="add" v-if="tabIndex==1"></page-edit>
            </md-tab>
            <md-tab md-label="Edit Page" v-if="tabIndex==2" :md-active="tabIndex==2">
                <page-edit ref="edit" :id="id" v-if="tabIndex==2"></page-edit>
            </md-tab>
        </md-tabs>
    </div>
</template>
<script>
import PageList from "@/components/Page/PageList";
import PageEdit from "@/components/Page/PageEdit";
export default {
    props: ["id"],
    components: {
        PageList,
        PageEdit
    },
    data() {
        return {
            tabIndex: 0
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                if (!this.id) {
                    this.tabIndex = 0;
                } else if (this.id == "new") {
                    this.tabIndex = 1;
                } else {
                    this.tabIndex = 2;
                }
            }
        }
    },
    methods: {
        tabChange(index) {
            if (index == 0) {
                this.$router.push("/admin/pages");
            }
            if (index == 1) {
                this.$router.push("/admin/pages/new");
            }
            if (index == 2) {
                this.$router.push(`/admin/pages/${this.id}`);
            }
        }
    }
};
</script>

