<template>
    <div class="page-list">
        <list :fs="pageFields" api="pages" title="Pages" editLink="/admin/pages"></list>
    </div>
</template>
<script>
import { pageFields } from "@/config";
import List from "@/components/List";
export default {
    components: {
        List
    },
    computed: {
        pageFields() {
            return pageFields;
        }
    }
};
</script>

