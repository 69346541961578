<template>
    <div class="page-edit">
        <edit :fs="pageFields" :id="id" @changed="changed" @saved="saved" api="pages" title="Pages"></edit>
    </div>
</template>
<script>
import Edit from "@/components/Edit";
import { pageFields } from "@/config";
export default {
    props: ["id"],
    components: {
        Edit
    },
    data() {
        return {
            mode: "Link"
        };
    },
    computed: {
        pageFields() {
            // let copyPageFields = JSON.parse(JSON.stringify(pageFields));
            // let contentField = copyPageFields.find(field => field.data == "content");
            // if (contentField) {
            //     contentField.type = this.mode == "Link" ? "text" : "editor";
            // }
            return pageFields.filter(field => field.data != "slug");
        }
    },
    methods: {
        saved() {
            this.$router.push("/admin/pages");
        },
        changed(model) {
            this.mode = model.type;
        }
    }
};
</script>

